.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal;
}
.ck.ck-content ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}


/* h2=heading 1 */
h2 {

	font-size:36px !important;
	font-weight: 400;
	line-height: normal;

	text-align: left;
	letter-spacing: 2px;
}
/* h3=heading 2 */
h3 {

	font-size: 29px !important;
	font-weight: 400;
	line-height: normal;
	
	text-align: left;
	letter-spacing: 1px;
}

/* h4=heading 3 */
h4 {

	font-size: 24px !important;
	font-weight: 400;
	line-height: normal;
	
	text-align: left;
	letter-spacing: 1px;
}